<template>
	<div class="report-layout" align="center">
		<div class="page">
			<HeaderReport :RadioName="nameRadio" :RadioLogo="logoRadio" />
			<div class="md-layout" style="padding: 0 0 0 0;">
				<div class="md-layout-item md-size-95" style="margin: 1rem auto;">
					<div
						style="display: flex; justify-content: space-between; align-items: center;"
					>
						<div>
							<h1 style="margin: 0; text-align:left; font-weight: bold;">
								Relatório de pedido musical
							</h1>
						</div>
						<div>
							<h5 style="margin: 0; text-align:right; font-weight: bold;">
								Período de apuração
							</h5>
							<h3 style="margin: 0; text-align:right; font-weight: bold;">
								{{ periodStart }} a {{ periodEnd }}
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div class="md-layout md-alignment-top-center">
				<div
					class="table-container md-layout-item md-size-90"
					style="margin: auto;"
				>
					<div class="md-layout md-gutter md-alignment-top-center">
						<h4 class="md-layout-item md-size-95" style="margin: 1rem;">
							Top 50 músicas mais pedidas
						</h4>
						<table
							class="md-layout-item md-size-45"
							style="margin-right: 1rem;"
						>
							<thead>
								<th>Posição</th>
								<th>Título</th>
								<th>Artista</th>
								<th>Total de pedidos</th>
							</thead>
							<tr
								class="tr_tables"
								v-for="(item, index) in data.tableMostRequestedSongs.slice(
									0,
									Math.ceil(data.tableMostRequestedSongs.length / 2)
								)"
								:key="index"
							>
								<td class="td_tables">{{ item.ranking }}</td>
								<td
									class="td_tables cell-for-title-and-artist"
								>
									{{ item.title }}
								</td>
								<td class="td_tables cell-for-title-and-artist">
									{{ item.artist }}
								</td>
								<td class="td_tables">
									{{ item.total_requests | formatNumber }}
								</td>
							</tr>
						</table>

						<table class="md-layout-item md-size-45" style="margin-left: 1rem;">
							<thead>
								<th>Posição</th>
								<th>Título</th>
								<th>Artista</th>
								<th>Total de pedidos</th>
							</thead>
							<tr
								class="tr_tables"
								v-for="(item, index) in data.tableMostRequestedSongs.slice(
									Math.ceil(data.tableMostRequestedSongs.length / 2),
									data.tableMostRequestedSongs.length
								)"
								:key="index"
							>
								<td class="td_tables">{{ item.ranking }}</td>
								<td
									class="td_tables cell-for-title-and-artist"
								>
									{{ item.title }}
								</td>
								<td
									class="td_tables cell-for-title-and-artist"
								>
									{{ item.artist }}
								</td>
								<td class="td_tables">
									{{ item.total_requests | formatNumber }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="page">
			<header class="second-page-header">
				<h3>Relatório de pedido musical</h3>
				<h3>{{ periodStart }} a {{ periodEnd }}</h3>
			</header>
			<div class="md-layout md-alignment-top-center">
				<div
					class="table-container md-layout-item md-size-90"
					style="margin: auto;"
				>
					<div class="md-layout md-alignment-top-center">
						<h4 class="md-layout-item md-size-95" style="margin: 1rem;">
							Top 50 músicas menos pedidas
						</h4>
						<table
							class="md-layout-item md-size-45"
							style="margin-right: 1rem;"
						>
							<thead>
								<th>Posição</th>
								<th>Título</th>
								<th>Artista</th>
								<th>Total</th>
							</thead>
							<tr
								class="tr_tables"
								v-for="(item, index) in data.tableLeastedRequestedSongs.slice(
									0,
									Math.ceil(data.tableLeastedRequestedSongs.length / 2)
								)"
								:key="index"
							>
								<td class="td_tables">{{ item.ranking }}</td>
								<td class="td_tables cell-for-title-and-artist">{{ item.title }}</td>
								<td class="td_tables cell-for-title-and-artist">{{ item.artist }}</td>
								<td class="td_tables">
									{{ item.total_requests | formatNumber }}
								</td>
							</tr>
						</table>

						<table class="md-layout-item md-size-45" style="margin-left: 1rem;">
							<thead>
								<th>Posição</th>
								<th>Título</th>
								<th>Artista</th>
								<th>Total</th>
							</thead>
							<tr
								class="tr_tables"
								v-for="(item, index) in data.tableLeastedRequestedSongs.slice(
									Math.ceil(data.tableLeastedRequestedSongs.length / 2),
									data.tableLeastedRequestedSongs.length
								)"
								:key="index"
							>
								<td class="td_tables">{{ item.ranking }}</td>
								<td class="td_tables cell-for-title-and-artist">{{ item.title }}</td>
								<td class="td_tables cell-for-title-and-artist">{{ item.artist }}</td>
								<td class="td_tables">
									{{ item.total_requests | formatNumber }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<FooterReport />
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import HeaderReport from "./partials/HeaderReport.vue";
import FooterReport from "./partials/FooterReport.vue";
import numeral from "numeral";
numeral.locale("pt-br");
Vue.filter("formatNumber", function(value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	name: "TemplateReportAudience",
	components: {
		HeaderReport,
		FooterReport
	},
	props: ["nameRadio", "logoRadio", "periodStart", "periodEnd", "data"]
};
</script>

<style scoped>
@page {
	size: auto;
	margin: 0 0 0 0;
}

@media print {
	body {
		overflow-y: hidden;
	}
	.page {
		width: 210mm;
		height: 296mm;
	}
}

ul {
	text-decoration: none;
}

ul li {
	display: inline-block;
	font-size: 12pt;
	background-color: #485ef3;
	color: #ffffff !important;
	height: 20px;
	padding: 3px 8px;
	margin: 3px;
	border-radius: 10px;
}

.report-layout td {
	text-align: center;
}

.report-layout {
	print-color-adjust: exact !important;
	-webkit-print-color-adjust: exact !important;
	background-color: #f4f4f4;
}
.page {
	position: relative;
}

.report-layout * {
	color: #707070;
	font-family: "Poppins" !important;
}

.second-page-header {
	width: 100%;
	height: 5rem;
	padding-top: 0.5rem;
	margin-bottom: 2rem;
	background: linear-gradient(90deg, #485ef3, #49b6ea);
}

.second-page-header h3 {
	color: #fff;
	font-weight: bold;
	margin: 0;
}

.table-container {
	margin: auto;
	background: #fff;
	width: 90%;
	padding: 1rem;
	border-radius: 3rem;
	border: 3px solid #485ef3;
}
.table-container h4 {
	font-weight: bold;
	font-size: 22px;
	grid-column: span 2;
}
.table-container * {
	color: #485ef3;
}
.table-container img {
	width: 16px;
}
.table-container table {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1rem;
}
.table-container td {
	border-bottom: 2px solid #485ef3;
	text-align: center;
	font-size: 9px;
	padding: 5px 0;
}
.first-col {
	font-size: 13px;
	font-weight: bol;
}
.cell-for-title-and-artist {
	font-size: 8px !important;
	white-space: nowrap;
	max-width: 3cm;
	overflow: hidden;
}
</style>
