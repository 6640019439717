<template>
	<div class="md-layout">
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div
			v-show="!loading"
			style="padding: 0; margin: 0"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<FilterSelector
				@searchData="getAllPromises()"
				@extraFunction="printReport()"
				:filterForReport="true"
				:buttonsToDisplay="[
					buttonEnum.YESTERDAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				:extraFunction="true"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="
					this.tableMostRequestedSongs.length === 0 ||
					this.tableLeastedRequestedSongs.length === 0
				"
			>
			</FilterSelector>
		</div>
		<div class="md-layout" v-show="!loading">
			<div
				v-if="this.tableMostRequestedSongs.length !== 0"
				style="padding-left: 0"
				class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 principal"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<h3>TOP 50 MÚSICAS MAIS PEDIDAS</h3>
					</md-card-header>

					<md-card-content>
						<table class="table_main_1">
							<thead style="text-align: left">
								<th>POSIÇÃO</th>
								<th>TÍTULO</th>
								<th>ARTISTA</th>
								<th>TOTAL DE PEDIDOS</th>
							</thead>
							<tbody>
								<tr
									class="tr_tables"
									v-for="(item, index) in this.tableMostRequestedSongs"
									:key="index"
								>
									<td class="td_tables">{{ item.ranking }}</td>
									<td class="td_tables">{{ item.title }}</td>
									<td class="td_tables">{{ item.artist }}</td>
									<td class="td_tables">{{ item.total_requests }}</td>
								</tr>
							</tbody>
						</table>
					</md-card-content>
				</md-card>
			</div>

			<div
				v-if="this.tableLeastedRequestedSongs.length !== 0"
				style="padding-right: 0"
				class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 principal"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<h3>TOP 50 MÚSICAS MENOS PEDIDAS</h3>
					</md-card-header>

					<md-card-content>
						<table class="table_main_1">
							<thead style="text-align: left">
								<th>POSIÇÃO</th>
								<th>TÍTULO</th>
								<th>ARTISTA</th>
								<th>TOTAL DE PEDIDOS</th>
							</thead>
							<tbody>
								<tr
									class="tr_tables"
									v-for="(item, index) in this.tableLeastedRequestedSongs"
									:key="index"
								>
									<td class="td_tables">{{ item.ranking }}</td>
									<td class="td_tables">{{ item.title }}</td>
									<td class="td_tables">{{ item.artist }}</td>
									<td class="td_tables">{{ item.total_requests }}</td>
								</tr>
							</tbody>
						</table>
					</md-card-content>
				</md-card>
			</div>

			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
				style="padding: 0"
			>
				<md-card style="text-align: center" v-show="msgError">
					<md-card-content>
						<label for="">Sem informações a serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
		</div>

		<TemplateReportRequestedSongs
			id="report"
			:nameRadio="this.radio.name"
			:logoRadio="this.radio.logo"
			:periodStart="this.periodStart"
			:periodEnd="this.periodEnd"
			:data="{
				tableMostRequestedSongs: this.tableMostRequestedSongs,
				tableLeastedRequestedSongs: this.tableLeastedRequestedSongs,
			}"
		/>
	</div>
</template>

<script>
import Vue from "vue";
import models from "../../../routes/models.js";
import selectRadio from "../../../utils/events/select-radio.js";
import jwt_decode from "jwt-decode";
import { DatePicker, Checkbox, CheckboxGroup } from "element-ui";
import Spinner from "../../../components/Icons/Spinner";
import formatDateToSQL from "../../../utils/formatDateToSQL";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters.js";
import buttonEnum from "../Components/FilterSelector/buttonEnum";
import TemplateReportRequestedSongs from "./templates/TemplateReportRequestedSongs.vue";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

const numeral = require("numeral");
numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
export default {
	components: {
		[DatePicker.name]: DatePicker,
		[CheckboxGroup.name]: CheckboxGroup,
		[Checkbox.name]: Checkbox,
		Spinner,
		FilterSelector,
		TemplateReportRequestedSongs,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			timeFormatedBr: null,
			datepicker: null,
			widthPicker: 100 + "%",
			loading: false,
			tableMostRequestedSongs: [],
			tableLeastedRequestedSongs: [],
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,

			periodStart: null,
			periodEnd: null,
			msgError: false,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			logo: "",
			disabledButton: true,
			btnImprimir: true,
			datePickerOptions: {
				disabledDate: (date) => {
					return date < new Date("2020-1-1");
				},
			},
		};
	},
	methods: {
		async getMostRequestedSongs() {
			const appKey = this.radio.key;
			const startDate = formatDateToSQL(filters.startDate);
			const endDate = formatDateToSQL(filters.endDate);
			await models()
				.get(
					`report/getTop50MostRequestedSongs?app_key=${appKey}&start_date=${startDate}&end_date=${endDate}`
				)
				.then((res) => {
					let counter = 0;
					this.tableMostRequestedSongs = res.data;
					this.tableMostRequestedSongs = this.tableMostRequestedSongs.map(
						(obj) => {
							counter += 1;
							return { ...obj, ranking: counter };
						}
					);
				});
		},

		async getLeastedRequestedSongs() {
			const appKey = this.radio.key;
			const startDate = formatDateToSQL(filters.startDate);
			const endDate = formatDateToSQL(filters.endDate);
			await models()
				.get(
					`report/getTop50LeastedRequestedSongs?app_key=${appKey}&start_date=${startDate}&end_date=${endDate}`
				)
				.then((res) => {
					let ranking = 0;
					this.tableLeastedRequestedSongs = res.data;
					this.tableLeastedRequestedSongs = this.tableLeastedRequestedSongs.map(
						(obj) => {
							ranking += 1;
							return { ...obj, ranking: ranking };
						}
					);
				});
		},
		getAllPromises() {
			this.loading = true;
			Promise.all([
				this.getMostRequestedSongs(),
				this.getLeastedRequestedSongs(),
			]).then(() => {
				this.loading = false;
				if (
					this.tableLeastedRequestedSongs.length === 0 &&
					this.tableMostRequestedSongs.length === 0
				) {
					this.msgError = true;
				} else {
					this.periodStart = filters.startDate.toLocaleDateString("pt-BR");
					this.periodEnd = filters.endDate.toLocaleDateString("pt-BR");
					this.msgError = false;
				}
			});
		},
		printReport() {
			window.print();
		}
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
		}

		selectRadio.$on("selectRadio", (payload) => {
			(this.appKey = payload.key), (this.nameRadioReport = payload.name);
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.msgError = false;
			this.tableMostRequestedSongs = [];
			this.tableLeastedRequestedSongs = [];
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;

			this.getAllPromises();
		});
	},
};
</script>

<style>
.table_main_1 tr:nth-child(odd) td {
	background: #cccccc24;
}

.table_main_1 tr:nth-child(even) td {
	background: #fff;
}

.table_main_3 {
	background: #cccccc24;
}

.impressao {
	display: none;
}

.md-table-head-label {
	font-weight: 800;
	color: #000000e0;
}

.td_tables,
.td_tables_media {
	text-align: left;
	width: 260px;
	padding: 10px 0px 10px 2px;
}

.border-icon-graph {
	display: inline-flex;
	padding: 20px;
	border-radius: 5px;
	position: absolute;
	box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
	background: linear-gradient(60deg, #26c6da, #00acc1);
}

.icon-graph {
	background-color: transparent !important;
	color: #ffffff;
}

.container-graph {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.datepicker-center {
	margin-top: -14px;
}

.table_main,
.table_main_1 {
	border-collapse: collapse;
}

.tr_tables {
	border-bottom: 1pt solid #dddddd;
}

canvas {
	padding: 60px 40px 60px 60px;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px #d9d9d9;
	background-color: #ffffff;
}

.md-card.main-rodape.md-theme-default {
	margin-top: 0px !important;
}

#report {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

@media print {
	#report {
		position: static;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		visibility: visible;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	canvas {
		height: 300px !important;
		padding: 10px;
		border: 1px solid #d9d9d9;
		border-radius: 0px;
		box-shadow: 0px 0px 0px 0px;
		background-color: #ffffff;
		margin-top: -30px;
		margin-bottom: -40px;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.principal {
		display: none;
	}

	.impressao {
		display: block;
	}

	.impressao-content {
		display: flex;
	}

	.periodo {
		margin-top: 10px;
		width: 100%;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleLeft {
		width: 100%;
	}

	.titleRight {
		float: right;
		width: 100%;
		text-align: right;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: 0px !important;
	}

	.contentBody {
		width: 100%;
		margin-top: 50px;
		padding: 1rem;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 10px;
		width: 100%;
	}

	.card-icon {
		display: none;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}

	.container-graph {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.md-card.main-rodape.md-theme-default {
		margin-top: -300px;
	}

	.td_tables_media {
		text-align: left;
		width: 240px;
		padding: 10px 0px 10px 0px;
	}
}
</style>
