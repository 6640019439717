var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-layout", attrs: { align: "center" } },
    [
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("HeaderReport", {
            attrs: { RadioName: _vm.nameRadio, RadioLogo: _vm.logoRadio },
          }),
          _c(
            "div",
            { staticClass: "md-layout", staticStyle: { padding: "0 0 0 0" } },
            [
              _c(
                "div",
                {
                  staticClass: "md-layout-item md-size-95",
                  staticStyle: { margin: "1rem auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _vm._m(0),
                      _c("div", [
                        _c(
                          "h5",
                          {
                            staticStyle: {
                              margin: "0",
                              "text-align": "right",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Período de apuração ")]
                        ),
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              margin: "0",
                              "text-align": "right",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.periodStart) +
                                " a " +
                                _vm._s(_vm.periodEnd) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "md-layout md-alignment-top-center" }, [
            _c(
              "div",
              {
                staticClass: "table-container md-layout-item md-size-90",
                staticStyle: { margin: "auto" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "md-layout md-gutter md-alignment-top-center",
                  },
                  [
                    _c(
                      "h4",
                      {
                        staticClass: "md-layout-item md-size-95",
                        staticStyle: { margin: "1rem" },
                      },
                      [_vm._v(" Top 50 músicas mais pedidas ")]
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "md-layout-item md-size-45",
                        staticStyle: { "margin-right": "1rem" },
                      },
                      [
                        _vm._m(1),
                        _vm._l(
                          _vm.data.tableMostRequestedSongs.slice(
                            0,
                            Math.ceil(
                              _vm.data.tableMostRequestedSongs.length / 2
                            )
                          ),
                          function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "tr_tables" },
                              [
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(_vm._s(item.ranking)),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(" " + _vm._s(item.artist) + " ")]
                                ),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.total_requests
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "md-layout-item md-size-45",
                        staticStyle: { "margin-left": "1rem" },
                      },
                      [
                        _vm._m(2),
                        _vm._l(
                          _vm.data.tableMostRequestedSongs.slice(
                            Math.ceil(
                              _vm.data.tableMostRequestedSongs.length / 2
                            ),
                            _vm.data.tableMostRequestedSongs.length
                          ),
                          function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "tr_tables" },
                              [
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(_vm._s(item.ranking)),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(" " + _vm._s(item.artist) + " ")]
                                ),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.total_requests
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("header", { staticClass: "second-page-header" }, [
            _c("h3", [_vm._v("Relatório de pedido musical")]),
            _c("h3", [
              _vm._v(_vm._s(_vm.periodStart) + " a " + _vm._s(_vm.periodEnd)),
            ]),
          ]),
          _c("div", { staticClass: "md-layout md-alignment-top-center" }, [
            _c(
              "div",
              {
                staticClass: "table-container md-layout-item md-size-90",
                staticStyle: { margin: "auto" },
              },
              [
                _c(
                  "div",
                  { staticClass: "md-layout md-alignment-top-center" },
                  [
                    _c(
                      "h4",
                      {
                        staticClass: "md-layout-item md-size-95",
                        staticStyle: { margin: "1rem" },
                      },
                      [_vm._v(" Top 50 músicas menos pedidas ")]
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "md-layout-item md-size-45",
                        staticStyle: { "margin-right": "1rem" },
                      },
                      [
                        _vm._m(3),
                        _vm._l(
                          _vm.data.tableLeastedRequestedSongs.slice(
                            0,
                            Math.ceil(
                              _vm.data.tableLeastedRequestedSongs.length / 2
                            )
                          ),
                          function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "tr_tables" },
                              [
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(_vm._s(item.ranking)),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(_vm._s(item.artist))]
                                ),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.total_requests
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "md-layout-item md-size-45",
                        staticStyle: { "margin-left": "1rem" },
                      },
                      [
                        _vm._m(4),
                        _vm._l(
                          _vm.data.tableLeastedRequestedSongs.slice(
                            Math.ceil(
                              _vm.data.tableLeastedRequestedSongs.length / 2
                            ),
                            _vm.data.tableLeastedRequestedSongs.length
                          ),
                          function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "tr_tables" },
                              [
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(_vm._s(item.ranking)),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "td_tables cell-for-title-and-artist",
                                  },
                                  [_vm._v(_vm._s(item.artist))]
                                ),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.total_requests
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c("FooterReport"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "h1",
        {
          staticStyle: {
            margin: "0",
            "text-align": "left",
            "font-weight": "bold",
          },
        },
        [_vm._v(" Relatório de pedido musical ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Posição")]),
      _c("th", [_vm._v("Título")]),
      _c("th", [_vm._v("Artista")]),
      _c("th", [_vm._v("Total de pedidos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Posição")]),
      _c("th", [_vm._v("Título")]),
      _c("th", [_vm._v("Artista")]),
      _c("th", [_vm._v("Total de pedidos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Posição")]),
      _c("th", [_vm._v("Título")]),
      _c("th", [_vm._v("Artista")]),
      _c("th", [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Posição")]),
      _c("th", [_vm._v("Título")]),
      _c("th", [_vm._v("Artista")]),
      _c("th", [_vm._v("Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }