var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_c("Spinner")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          staticStyle: { padding: "0", margin: "0" },
        },
        [
          _c("FilterSelector", {
            attrs: {
              filterForReport: true,
              buttonsToDisplay: [
                _vm.buttonEnum.YESTERDAY,
                _vm.buttonEnum.SEVEN_DAYS,
                _vm.buttonEnum.MONTH_TO_DATE,
                _vm.buttonEnum.CUSTOM,
              ],
              extraFunction: true,
              extraFunctionLabel: "IMPRIMIR",
              disableExtraFunction:
                this.tableMostRequestedSongs.length === 0 ||
                this.tableLeastedRequestedSongs.length === 0,
            },
            on: {
              searchData: function ($event) {
                return _vm.getAllPromises()
              },
              extraFunction: function ($event) {
                return _vm.printReport()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "md-layout",
        },
        [
          this.tableMostRequestedSongs.length !== 0
            ? _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 principal",
                  staticStyle: { "padding-left": "0" },
                },
                [
                  _c(
                    "md-card",
                    [
                      _c(
                        "md-card-header",
                        {
                          staticClass:
                            "md-card-header-icon md-card-header-blue",
                        },
                        [_c("h3", [_vm._v("TOP 50 MÚSICAS MAIS PEDIDAS")])]
                      ),
                      _c("md-card-content", [
                        _c("table", { staticClass: "table_main_1" }, [
                          _c(
                            "thead",
                            { staticStyle: { "text-align": "left" } },
                            [
                              _c("th", [_vm._v("POSIÇÃO")]),
                              _c("th", [_vm._v("TÍTULO")]),
                              _c("th", [_vm._v("ARTISTA")]),
                              _c("th", [_vm._v("TOTAL DE PEDIDOS")]),
                            ]
                          ),
                          _c(
                            "tbody",
                            _vm._l(
                              this.tableMostRequestedSongs,
                              function (item, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "tr_tables" },
                                  [
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.ranking)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.artist)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.total_requests)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          this.tableLeastedRequestedSongs.length !== 0
            ? _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 principal",
                  staticStyle: { "padding-right": "0" },
                },
                [
                  _c(
                    "md-card",
                    [
                      _c(
                        "md-card-header",
                        {
                          staticClass:
                            "md-card-header-icon md-card-header-blue",
                        },
                        [_c("h3", [_vm._v("TOP 50 MÚSICAS MENOS PEDIDAS")])]
                      ),
                      _c("md-card-content", [
                        _c("table", { staticClass: "table_main_1" }, [
                          _c(
                            "thead",
                            { staticStyle: { "text-align": "left" } },
                            [
                              _c("th", [_vm._v("POSIÇÃO")]),
                              _c("th", [_vm._v("TÍTULO")]),
                              _c("th", [_vm._v("ARTISTA")]),
                              _c("th", [_vm._v("TOTAL DE PEDIDOS")]),
                            ]
                          ),
                          _c(
                            "tbody",
                            _vm._l(
                              this.tableLeastedRequestedSongs,
                              function (item, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "tr_tables" },
                                  [
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.ranking)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.artist)),
                                    ]),
                                    _c("td", { staticClass: "td_tables" }, [
                                      _vm._v(_vm._s(item.total_requests)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              staticStyle: { padding: "0" },
            },
            [
              _c(
                "md-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.msgError,
                      expression: "msgError",
                    },
                  ],
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("md-card-content", [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Sem informações a serem exibidas."),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("TemplateReportRequestedSongs", {
        attrs: {
          id: "report",
          nameRadio: this.radio.name,
          logoRadio: this.radio.logo,
          periodStart: this.periodStart,
          periodEnd: this.periodEnd,
          data: {
            tableMostRequestedSongs: this.tableMostRequestedSongs,
            tableLeastedRequestedSongs: this.tableLeastedRequestedSongs,
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }